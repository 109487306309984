import React from 'react';
import qs from 'query-string';
import {Helmet} from 'react-helmet';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';

export default function StoreCouponPage(props) {
  const params = qs.parse(props.location.search);
  const {store_id} = params;
  const qrcodeRef = React.useRef();
  const domRef = React.useRef();
  const [store, setStore] = React.useState();
  const [couponTemplate, setCouponTemplate] = React.useState();

  React.useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      store_id &&
      store?.stamp_change_coupon
    ) {
      setTimeout(() => {
        console.log('QRCode', window.QRCode);
        if (typeof window.QRCode !== 'undefined') {
          qrcodeRef.current = new window.QRCode(domRef.current, {
            // text: `${window.location.hostname}/coupon?store=${store_id}&coupon_template=${store.stamp_change_coupon}`,
            text: `https://now.laya.com.tw/coupon?store=${store_id}&coupon_template=${store.stamp_change_coupon}`,
            width: 256,
            height: 256,
            colorDark: '#000000',
            colorLight: '#ffffff',
            correctLevel: window.QRCode.CorrectLevel.H,
          });
        }
      }, 1000);
    }

    return () => {
      if (qrcodeRef.current) {
        qrcodeRef.current.clear();
      }
    };
  }, [store_id, store]);

  React.useEffect(() => {
    (async () => {
      if (store_id) {
        let store;
        try {
          store = await JStorage.fetchOneDocument('store', {
            id: store_id,
          });
          setStore(store);
        } catch (err) {
          console.log(err);
        }

        try {
          if (store?.stamp_change_coupon) {
            let couponTmp = await JStorage.fetchOneDocument('coupon_template', {
              id: store.stamp_change_coupon,
            });
            setCouponTemplate(couponTmp);
          }
        } catch (err) {
          console.log(err);
        }
      }
    })();
  }, [store_id]);

  return (
    <div style={{padding: 20}}>
      <Helmet>
        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/qrcodejs/1.0.0/qrcode.min.js"
          integrity="sha512-CNgIRecGo7nphbeZ04Sc13ka07paqdeTu0WR1IM4kNcpmBAUSHSQX0FslNhTDadL4O5SAGapGt4FodqL8My0mA=="
          crossorigin="anonymous"
          referrerpolicy="no-referrer"></script>
      </Helmet>
      {!store?.stamp_change_coupon && (
        <div style={{marginBottom: 20, fontSize: 20, color: 'red'}}>
          找不到優惠券樣板，請至店家設定
        </div>
      )}
      <div
        style={{
          padding: 10,
          border: 'solid 1px #ddd',
          display: 'inline-block',
        }}>
        <div ref={domRef} />
        <div style={{textAlign: 'center', marginTop: 10}}>
          {store?.name} QRcode
        </div>
        {couponTemplate && (
          <div style={{textAlign: 'center', marginTop: 5}}>
            {couponTemplate.name}
          </div>
        )}
      </div>
    </div>
  );
}
